// Footer.js
import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Obtiene el año actual

    return (
        <footer className="bg-gray-800 text-white text-center p-4 md:p-6 lg:p-8">
            <div className="container mx-auto">
                <p className="text-sm md:text-base">
                    ¿Necesitas ayuda? Llama al <a href="tel:+50684839675" className="font-bold underline">+506 84839675</a> o escribe a <a href="mailto:info@tridniostudios.com" className="font-bold underline">info@tridniostudios.com</a>
                </p>
                <p className="text-xs md:text-sm mt-2">
                    Ofrecemos una garantía de un mes a partir de la fecha de compra, cubriendo solo defectos de fabricación y no daños ocasionados por el uso incorrecto o manipulación por parte del cliente.
                </p>
                <p className="text-lg md:text-xl mt-4">
                    Imagenes con fines ilustrativos
                </p>
                <p className="text-xs md:text-sm mt-4">
                    © {currentYear} Tridnio Studios. Todos los derechos reservados.
                </p>
            </div>
        </footer>
    );
};

export default Footer;


