import React, { useEffect, useState } from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import TicketDetailView from '../Components/Dashboard/TicketDetailView'
import TicketTab from '../Components/Dashboard/TicketTab'
import TicketListView from '../Components/Dashboard/TicketListView'
// Datos de ejemplo para los gráficos
const data = [
    { name: 'Ene', Estudiantes: 200, Profesores: 150, Tickets: 24 },
    { name: 'Feb', Estudiantes: 300, Profesores: 138, Tickets: 22 },
    { name: 'Mar', Estudiantes: 200, Profesores: 500, Tickets: 27 },
    { name: 'Abr', Estudiantes: 278, Profesores: 390, Tickets: 20 },
    { name: 'May', Estudiantes: 189, Profesores: 480, Tickets: 28 },
];

const pieData = [
    { name: 'Grupo A', value: 250 },
    { name: 'Grupo B', value: 300 },
    { name: 'Grupo C', value: 300 },
    { name: 'Grupo D', value: 200 },
];



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const TicketSystem = () => {
    const [tabs, setTabs] = useState([{ id: 'opened', title: 'Tickets Opened', content: 'ListView' }]);
    const [selectedTab, setSelectedTab] = useState('opened');
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        const fetchTickets = async () => {
            const response = await fetch('https://tridnio-api-73dsl.ondigitalocean.app/api/tickets');
            const data = await response.json();
            const formattedData = data.map(ticket => ({
                id: ticket.orderId, 
                subject: ticket.subject,
                status: ticket.status,
                priority: ticket.priority,
                dateOpened: '2022-01-01' ,
                name:ticket.name,
                lastName: ticket.lastName,
                email: ticket.email,
                address: ticket.address,
                phone: ticket.phone
            }));
            console.log(formattedData);  
            setTickets(formattedData);
        };

        fetchTickets();
    }, []);



    const handleSelectTicket = (ticket) => {
        // Aquí establece el estado para mostrar la vista detallada del ticket seleccionado
        setSelectedTab(ticket.id);
        // Y añade la pestaña del ticket si no está ya en las pestañas abiertas
        if (!tabs.some(tab => tab.id === ticket.id)) {
            setTabs([...tabs, { id: ticket.id, title: `Ticket #${ticket.id}`, content: 'DetailView' }]);
        }
    };

    const handleStatusChange = (newStatus) => {
        // Update the ticket's status in the state or send an update to the server
    };

    const handleCloseTab = (tabId) => {
        setTabs(tabs.filter(tab => tab.id !== tabId));
        if (selectedTab === tabId) {
            setSelectedTab('opened');
        }
    };

    const handleSelectTab = (tabId) => {
        setSelectedTab(tabId);
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'opened':
                return <TicketListView tickets={tickets} onTicketSelect={handleSelectTicket} />;
            default:
                // Manejar la vista detallada del ticket cuando se selecciona desde una pestaña
                const ticket = tickets.find(ticket => ticket.id === selectedTab);
                return ticket && <TicketDetailView ticket={ticket} onStatusChange={handleStatusChange} />;
        }
    };
    

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-semibold text-gray-800 mb-8">Dashboard</h1>
                {/* Sección de Análisis 
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {/* Gráfico de Líneas 
                    <div className="p-4 bg-white rounded-lg shadow">
                        <h2 className="text-lg font-semibold text-gray-800 mb-2">Crecimiento</h2>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Estudiantes" stroke="#8884d8" />
                                <Line type="monotone" dataKey="Profesores" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    {/* Gráfico de Pastel 
                    <div className="p-4 bg-white rounded-lg shadow">
                        <h2 className="text-lg font-semibold text-gray-800 mb-2">Distribución</h2>
                        <ResponsiveContainer width="100%" height={250}>
                            <PieChart>
                                <Pie data={pieData} cx="50%" cy="50%" outerRadius={80} fill="#8884d8" dataKey="value" label>
                                    {pieData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    /*}
                </div>
                {/* Sección de tarjetas informativas 
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
                     Tarjeta Profes 
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex items-center space-x-4">
                            <img src="https://via.placeholder.com/100" alt="Profes" className="w-16 h-16 object-cover rounded-full" />
                            <h2 className="text-xl font-semibold">Profes</h2>
                        </div>
                        <p className="text-gray-600 mt-2">Controla y gestiona la información de los profesores</p>
                    </div>

                     Tarjeta Estudiantes 
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex items-center space-x-4">
                            <img src="https://via.placeholder.com/100" alt="Estudiantes" className="w-16 h-16 object-cover rounded-full" />
                            <h2 className="text-xl font-semibold">Estudiantes</h2>
                        </div>
                        <p className="text-gray-600 mt-2">Accede a la base de datos de los estudiantes</p>
                    </div>
                    </div>

                         Tarjeta del sistema de Tickets, más amplia y debajo de las otras 
                
                */}
                <div className="grid grid-cols-1 gap-6 mt-6">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Ticket System</h2>

                        </div>
                        {/* Tabs display */}
                        <div className="tabs flex -mb-px">
                            {tabs.map((tab) => (
                                <TicketTab
                                    key={tab.id}
                                    title={tab.title}
                                    isSelected={selectedTab === tab.id}
                                    onCloseClick={() => handleCloseTab(tab.id)}
                                    onClick={() => handleSelectTab(tab.id)} // Add onClick prop to TicketTab
                                />
                            ))}
                        </div>
                        <div className="tab-content mt-4">
                            {renderTabContent()}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default TicketSystem;