import React, { useState, useEffect } from 'react';
import { ShoppingCartIcon } from '@heroicons/react/outline';

import toast from 'react-hot-toast';

// Nueva paleta de colores basada en la imagen
// Ejemplo de colores disponibles
const sizes = ['XS', 'S', 'M', 'L', 'XL']; // Ejemplo de tallas disponibles

const colorMap = {
    '#FFD700': 'Amarillo',
    '#0000FF': 'Azul',
    '#87CEEB': 'Azul Cielo',
    '#00008B': 'Azul Oscuro',
    '#FFFFFF': 'Blanco',
    '#808080': 'Gris',
    '#800080': 'Morado',
    '#FFA500': 'Naranja',
    '#000000': 'Negro',
    '#FF0000': 'Rojo',
    '#FFC0CB': 'Rosado',
    '#C0C0C0': 'Silver',
    '#008000': 'Verde'
};

const colorCodes = [
    '#FFD700', // Amarillo
    '#0000FF', // Azul
    '#87CEEB', // Azul Cielo
    '#00008B', // Azul Oscuro
    '#FFFFFF', // Blanco
    '#808080', // Gris
    '#800080', // Morado
    '#FFA500', // Naranja
    '#000000', // Negro
    '#FF0000', // Rojo
    '#FFC0CB', // Rosado
    '#C0C0C0', // Silver
    '#008000', // Verde
];

const getRandomColorCode = () => {
    const randomIndex = Math.floor(Math.random() * colorCodes.length);
    return colorCodes[randomIndex];
};


const ProductCard = ({ product, addToCart }) => {
    const colorCodes = Object.keys(product.colors);
    const initialColorCode = colorCodes.length === 1 || colorCodes.length === 5 ? colorCodes[0] : getRandomColorCode();  // Si solo hay un color, usa ese.  // El primer color disponible
    const [selectedColor, setSelectedColor] = useState(initialColorCode);
    const [selectedSize, setSelectedSize] = useState('XS');  // Asegúrate de que 'XS' es un valor válido
    const [quantity, setQuantity] = useState(1);
    const [image, setImage] = useState(product.colors[initialColorCode]);

    useEffect(() => {
        setImage(product.colors[selectedColor]);  // Actualiza la imagen cuando cambia selectedColor
    }, [selectedColor, product.colors]);

    const selectColor = (colorCode) => {
        setSelectedColor(colorCode);  // Actualiza el estado con el código del color seleccionado
    };

    const selectSize = (size) => {
        setSelectedSize(size);
    };

    const incrementQuantity = () => {
        setQuantity((prevQuantity) => prevQuantity < 5 ? prevQuantity + 1 : prevQuantity);
    };

    const decrementQuantity = () => {
        setQuantity((prevQuantity) => prevQuantity > 1 ? prevQuantity - 1 : prevQuantity);
    };

    const handleAddToCart = () => {
        const colorName = colorMap[selectedColor];  // Asegúrate de que colorMap se importa o se define en este componente
        const newItem = {
            id: product.id,
            nombre: product.name,
            color: colorName,  // Ahora pasa el nombre del color
            talla: selectedSize,
            cantidad: quantity,
            precio: product.price,
            imagen: image
        };
        addToCart(newItem);
        toast.success('Producto agregado al carrito');
    };

    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg m-4 bg-white">
            <img className="w-full" src={image} alt={product.name} />
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{product.name}</div>
                <p className="text-gray-700 text-base">
                    {product.description}
                </p>
                {/* Colores */}
                <div className="flex items-center mt-2">
                    {colorCodes.map((color) => (
                        <span
                            key={color}
                            className={`inline-block w-6 h-6 border-2 rounded-full mr-2 cursor-pointer ${selectedColor === color ? 'border-black' : 'border-gray-300'}`}
                            style={{ backgroundColor: color }}
                            onClick={() => selectColor(color)}
                        />
                    ))}
                </div>
                {/* Tallas */}
                {product.hasSizes === false ? (
                    <div className="text-sm text-gray-500">Talla ajustable disponible</div>
                ) : (
                <div className="flex items-center mt-3">
                    {sizes.map((size) => (
                        <span
                            key={size}
                            className={`text-sm font-semibold mr-2 px-2 py-1 border rounded cursor-pointer ${selectedSize === size ? 'bg-gray-300' : 'hover:bg-gray-200'}`}
                            onClick={() => selectSize(size)}
                        >
                            {size}
                        </span>
                    ))}
                </div>
                )}
                {/* Cantidad */}
                <div className="flex items-center mt-3">
                    <button onClick={decrementQuantity} className="text-sm font-semibold px-2 py-1 border rounded cursor-pointer hover:bg-gray-200">-</button>
                    <span className="text-sm px-4">{quantity}</span>
                    <button onClick={incrementQuantity} className="text-sm font-semibold px-2 py-1 border rounded cursor-pointer hover:bg-gray-200">+</button>
                </div>
            </div>
            <div className="px-6 pt-4 pb-2 flex justify-between items-center">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">¢{product.price}</span>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                    onClick={handleAddToCart}
                >
                    <ShoppingCartIcon className="h-5 w-5 mr-2" />
                    Añadir al carrito
                </button>
            </div>
        </div>
    );
};

export default ProductCard;
