import React, { useEffect, useRef, useState } from 'react';
import { EmailEditor } from 'react-email-editor';
import 'react-quill/dist/quill.snow.css';
import { Toaster, toast } from 'react-hot-toast';

const statusSteps = [
    { name: 'In process', color: 'bg-red-500 hover:bg-red-600' },
    { name: 'Sent', color: 'bg-orange-500 hover:bg-orange-600' },
    { name: 'Delivered', color: 'bg-green-500 hover:bg-green-600' },
];

const emailTemplates = {
    'Payment received': `
    <div style="font-family: Arial, sans-serif; background-color: #f4f4f4; color: #333; width: 80%; max-width: 600px; margin: 20px auto; padding: 20px; box-shadow: 0 0 10px rgba(0,0,0,0.1);">
    <h1 style="color: #0056b3;">Confirmación de Pago</h1>
    <p>Estimado [Nombre del Cliente],</p>
    <p>¡Gracias por tu compra en Tridnio Store! Estamos encantados de confirmar que hemos recibido tu pago y tu pedido está en proceso de ser preparado para el envío.</p>
    <p>El envío está programado para realizarse en un plazo de <b>3-5 días hábiles</b>. Te enviaremos un nuevo correo electrónico para avisarte cuando el pedido esté en camino.</p>
    <p>Si tienes alguna pregunta o necesitas hacer algún cambio en tu pedido, por favor, no dudes en contactarnos. Estamos aquí para ayudarte.</p>
    <p>Gracias nuevamente por elegir Tridnio Store. ¡Esperamos que disfrutes tu compra!</p>
    <div style="margin-top: 20px; font-size: 0.9em; text-align: center; color: #777;">
        <p>Saludos cordiales,<br>El equipo de Tridnio Store</p>
    </div>
</div>
    `,
    'Sent': `
    <div style="font-family: Arial, sans-serif; background-color: #f4f4f4; color: #333; width: 80%; max-width: 600px; margin: 20px auto; padding: 20px; box-shadow: 0 0 10px rgba(0,0,0,0.1);">
    <h2 style="color: #0056b3;">Confirmación de Envío</h2>
    <p>Estimado [Nombre del Cliente],</p>
    <p>Tu pedido ha sido enviado y deberías recibirlo pronto. Agradecemos tu paciencia y preferencia.</p>
    <div style="margin-top: 20px; font-size: 0.9em; text-align: center; color: #777;">
        <p>Saludos cordiales,<br>El equipo de Tridnio Store</p>
    </div>
</div>

    `,
    'Delivered': `
    <div style="font-family: Arial, sans-serif; background-color: #f4f4f4; color: #333; width: 80%; max-width: 600px; margin: 20px auto; padding: 20px; box-shadow: 0 0 10px rgba(0,0,0,0.1);">
    <h2 style="color: #0056b3;">Confirmación de Entrega</h2>
    <p>Estimado [Nombre del Cliente],</p>
    <p>Tu pedido ha sido entregado con éxito. Esperamos que disfrutes tus productos y te agradecemos por confiar en Tridnio Store.</p>
    <div style="margin-top: 20px;">
        <p>¿Cómo evaluarías tu experiencia con nosotros?</p>
        <div style="font-size: 24px;">
            😃 Muy satisfecho | 😊 Satisfecho | 😐 Neutral | 😟 Insatisfecho | 😢 Muy insatisfecho
        </div>
        <textarea style="width: 100%; margin-top: 10px; padding: 10px; border: 1px solid #ccc; border-radius: 5px;" id="comments" placeholder="Comentarios adicionales..."></textarea>
    </div>
    <div style="margin-top: 20px; font-size: 0.9em; text-align: center; color: #777;">
        <p>Saludos cordiales,<br>El equipo de Tridnio Store</p>
    </div>
</div>
`
};



const TicketDetailView = ({ ticket, onStatusChange }) => {
    const emailEditorRef = useRef(null);
    const [status, setStatus] = useState(ticket.status);
    const [priority, setPriority] = useState(ticket.priority);
    const [isPaid, setIsPaid] = useState(ticket.priority === 'Paid');
    const [copySuccess, setCopySuccess] = useState('');
    const [editorReady, setEditorReady] = useState(false);
    const [emailContent, setEmailContent] = useState('');
    const [isEmailReady, setIsEmailReady] = useState(false);
    const [emailHistory, setEmailHistory] = useState([]);


    const toggleExpand = (index) => {
        setEmailHistory(currentHistory =>
            currentHistory.map((email, idx) => idx === index ? { ...email, expanded: !email.expanded } : email)
        );
    };



    useEffect(() => {
        const fetchTicketDetails = async () => {
            if (!ticket.id) {
                console.log("No ticket ID available to fetch details.");
                return; // Early return if no ticket ID is available
            }
            try {
                const response = await fetch(`https://tridnio-api-73dsl.ondigitalocean.app/api/tickets/${ticket.id}`);
                if (!response.ok) {
                    // If the response is not OK, throw an error with the status text
                    throw new Error(`Failed to fetch ticket details: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                if (data.emailHistory) {
                    setEmailHistory(data.emailHistory);
                } else {
                    // Handle cases where there is no email history in the response
                    setEmailHistory([]);
                    console.log("No email history available for this ticket.");
                }
            } catch (error) {
                // Display the error using a toast notification
                toast.error(`Error fetching ticket details: ${error.message}`);
                console.error('Error fetching ticket details:', error);
            }
        };
        fetchTicketDetails();
    }, [ticket.id]); // Depend on ticket.id to re-run the effect when it changes




    const onLoad = () => {
        setEditorReady(true);
        emailEditorRef.current.editor.addEventListener('design:updated', () => {
            emailEditorRef.current.editor.exportHtml((data) => {
                const { html } = data;
                setEmailContent(html);
                setIsEmailReady(html.length > 0);
            });
        });
    };


    const personalizeTemplate = (templateContent) => {
        return templateContent.replace('[Nombre del Cliente]', `${ticket.name} ${ticket.lastName}`);
    };



    // Define the status button class function
    const statusButtonClass = (step) => {
        return status === step.name ? `${step.color} text-white` : "bg-gray-200 text-gray-600 hover:text-black";
    };

    const handleStatusChange = async (newStatus) => {
        try {
            const response = await fetch(`https://tridnio-api-73dsl.ondigitalocean.app/api/tickets/${ticket.id}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus })
            });
            if (!response.ok) throw new Error('Failed to update ticket status');
            const result = await response.json(); // Assuming the server sends some response
            setStatus(newStatus);
            onStatusChange(newStatus);
            toast.success('Status updated successfully!');
        } catch (error) {
            console.error('Error updating ticket status:', error);
            toast.error(`Failed to update status: ${error.message}`);
        }
    };


    const handlePriorityChange = async (e) => {
        const newPriority = e.target.checked ? 'Paid' : 'Unpaid';
        setIsPaid(e.target.checked);
        try {
            const response = await fetch(`https://tridnio-api-73dsl.ondigitalocean.app/api/tickets/${ticket.id}/priority`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ priority: newPriority })
            });
            if (!response.ok) throw new Error('Failed to update ticket priority');
            toast.success('Priority updated successfully!');
        } catch (error) {
            console.error('Error updating ticket priority:', error);
            toast.error(`Failed to update priority: ${error.message}`);
        }
    };


    const handleTemplateSelect = (template) => {
        const personalizedContent = personalizeTemplate(emailTemplates[template]);
        if (editorReady && emailEditorRef.current) {
            const designJson = {
                body: {
                    rows: [
                        {
                            cells: [1],
                            columns: [
                                {
                                    contents: [
                                        {
                                            type: 'html',
                                            values: {
                                                html: personalizedContent
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            };

            emailEditorRef.current.editor.loadDesign(designJson);
        } else {
            console.log('Editor not ready or reference missing');
        }
    };

    const handleSendEmail = async () => {
        if (!isEmailReady) {
            toast.error('No content to send.');
            return;
        }

        const emailInfo = {
            email: ticket.email,
            subject: 'Details of your order with Tridnio Store',
            message: emailContent,
            ticketId: ticket.id
        };

        try {
            const response = await fetch('https://tridnio-api-73dsl.ondigitalocean.app/api/send-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(emailInfo)
            });
            if (!response.ok) {
                const errorText = await response.text();
                toast.error(`Failed to send email: ${errorText}`);
                return;
            }
            const result = await response.json();
            setEmailHistory([...emailHistory, result.emailRecord]);
            toast.success('Email sent successfully!');
        } catch (error) {
            toast.error('Error while sending email.');
            console.error('Error while sending email:', error);
        }
    };
    return (
        <div className="p-6 border rounded-lg shadow-lg flex flex-col bg-white">
            <Toaster />
            <div className="border-b pb-4 mb-4">
                <h4 className="font-bold mb-2">Select Email Template</h4>
                <div className="flex justify-between">
                    {Object.keys(emailTemplates).map(templateKey => (
                        <button key={templateKey}
                            onClick={() => handleTemplateSelect(templateKey)}
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">
                            Load {templateKey} Template
                        </button>
                    ))}
                </div>
                <h3 className="text-xl font-semibold mb-2">Ticket Information</h3>
                <div className="flex">
                    <div className="flex-1">
                        <h4 className="font-bold">User Details</h4>
                        <p>ID: {ticket.id}</p>
                        <p>User: {ticket.name + ' ' + ticket.lastName}</p>
                        <p>Email: {ticket.email}</p>
                    </div>
                    <div className="flex-1">
                        <h4 className="font-bold">Details</h4>
                        <p>Subject: {ticket.subject}</p>
                        <p>Status: {status}</p>
                        <p>Priority: {priority}</p>
                        <p>Date Opened: {ticket.dateOpened}</p>
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <h4 className="font-bold mb-2">Status</h4>
                <div className="flex justify-between">
                    {statusSteps.map(step => (
                        <button key={step.name} onClick={() => handleStatusChange(step.name)}
                            className={`flex-1 text-center py-1 text-sm rounded-lg mx-1 ${statusButtonClass(step)}`}>
                            {step.name}
                        </button>
                    ))}
                </div>
            </div>
            <div className="mb-4">
                <label className="inline-flex items-center">
                    <input type="checkbox" checked={isPaid} onChange={handlePriorityChange}
                        className="form-checkbox h-5 w-5 text-blue-600" />
                    <span className="ml-2 text-gray-700">Paid</span>
                </label>
            </div>
            {/* email editor */}
            <div className="flex-grow mb-4">
                <div className="email-editor">
                    <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
                </div>
            </div>
            <div className="border-t pt-4 flex justify-between">
                <button
                    onClick={handleSendEmail}
                    disabled={!isEmailReady}  // Deshabilita el botón si el correo no está listo
                    className={`bg-green-500 text-white py-2 px-4 rounded ${!isEmailReady ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-600'}`}>
                    Enviar Correo
                </button>
            </div>
            <div className="mt-6 space-y-4">  {/* Increased margin-top here */}
                {emailHistory.map((email, index) => (
                    <div key={index} className="bg-white shadow rounded-lg p-4">
                        <div className="flex justify-between items-center">
                            <div>
                                <p className="text-sm text-gray-600">Date Sent: {new Date(email?.sentDate).toLocaleString()}</p>
                                <p className="font-semibold">Subject: {email?.subject || 'No Subject'}</p>
                                <p>To: {email?.recipient || 'No Recipient'}</p>
                            </div>
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                onClick={() => toggleExpand(index)}
                            >
                                {email.expanded ? 'Hide Details' : 'View Details'}
                            </button>
                        </div>
                        {email.expanded && (
                            <div className="mt-4">
                                <p className="text-gray-800 font-semibold">Full Message:</p>
                                <div
                                    className="mt-2 p-2 bg-gray-100 rounded text-gray-800 overflow-hidden shadow-inner"
                                    dangerouslySetInnerHTML={{ __html: email?.message }}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>

        </div>
    );
};

export default TicketDetailView;
