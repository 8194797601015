import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/Img/TRIDNIO STORE-02.png';
import { ShoppingCartIcon } from '@heroicons/react/outline';

const ShopNavbar = ({ toggleCartPopup, cartItems }) => {
    const totalItems = cartItems.reduce((total, item) => total + item.cantidad, 0);

    return (
        <header className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
            <div className="container mx-auto flex justify-between items-center p-4">
                <Link to="/">
                    <img src={Logo} alt="Logo de la tienda" className="h-12 w-auto" />
                </Link>
                <nav>
                    <ul className="flex space-x-6">
                        <li>
                            <Link to="/" className="text-gray-600 hover:text-gray-800">
                                Inicio
                            </Link>
                        </li>

                        <li>
                            <button onClick={toggleCartPopup} className="text-gray-600 hover:text-gray-800 relative">
                                <ShoppingCartIcon className="h-6 w-6 inline-block" />
                                {totalItems > 0 && (
                                    <span className="absolute -right-1 -top-1 rounded-full bg-red-600 text-white p-1 text-xs min-w-5 h-5 flex items-center justify-center">
                                        {totalItems}
                                    </span>
                                )}
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default ShopNavbar;
