import React, { useState } from 'react';
import { CheckCircleIcon, XIcon, RefreshIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';

const PaymentOptionsPopup = ({
    isOpen,
    onClose,
    cartItems,
    resetCart,
    sendOrder,
    shippingCost,
    orderId
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const subtotal = cartItems.reduce(
        (acc, item) => acc + item.cantidad * item.precio,
        0
    );


    // Determinar si se debe aplicar envío gratis
    const isFreeShipping = subtotal > 20000;
    const effectiveShippingCost = isFreeShipping ? 0 : shippingCost;

    const total = subtotal + effectiveShippingCost;

    // Envío del pedido con token reCAPTCHA
    const submitOrder = (token) => {
        sendOrder(cartItems, effectiveShippingCost, orderId, token, (success) => {
            if (success) {
                toast.success('Orden enviada con éxito');
                resetCart();
                onClose();
            } else {
                toast.error('Error al enviar la orden');
            }
            setIsLoading(false);
        });
    };

    const executeRecaptcha = async () => {
        setIsLoading(true);
        window.grecaptcha.ready(async () => {
            const token = await window.grecaptcha.execute('6LeVN3weAAAAAKX_XO3ed6MpIYL9aw1fsiAw6BsM', { action: 'submit' });
            submitOrder(token);
        });
    };

    if (!isOpen) return null;

    return (
        <div className={`fixed inset-0 z-30 overflow-y-auto ${!isOpen && 'hidden'}`}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* Overlay */}
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>

                {/* Centrado vertical para pantallas pequeñas */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    {/* Header con título e ícono de cierre */}
                    <div className="flex justify-between items-start">
                        <div className="flex items-center">
                            <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                            <h3 className="text-lg leading-6 font-medium text-gray-900 ml-3">
                                Confirmación de compra
                            </h3>
                        </div>
                        <XIcon className="h-6 w-6 text-gray-400 cursor-pointer" onClick={onClose} aria-hidden="true" />
                    </div>

                    {/* Mensaje y detalles de la orden */}
                    <div className="mt-5">
                        <p className="text-sm text-gray-500">
                            Su pedido <strong>{orderId}</strong> está listo para el último paso. Por favor, realice el pago para completar su compra.
                        </p>
                        <p className="text-sm text-gray-500 mt-2">
                            Complete su pago dentro de la próxima hora para asegurar su pedido.
                        </p>
                        <div className="mt-4 space-y-2">
                            <p className="font-semibold">Subtotal: ¢{subtotal.toLocaleString()}</p>
                            {/* Actualizar mensaje y costo de envío según si es gratis o no */}
                            <p className="font-semibold">
                                Envío: {isFreeShipping ? '¡GRATIS!' : `¢${effectiveShippingCost.toLocaleString()}`}
                            </p>
                            <p className="font-semibold text-lg">Total: ¢{total.toLocaleString()}</p>
                        </div>
                    </div>

                    {/* Información de pago */}
                    <div className="mt-5 bg-gray-50 p-4 rounded-md">
                        <h4 className="text-sm font-semibold text-gray-700">Opciones de pago</h4>
                        <ul className="list-inside space-y-1 mt-2 text-sm">
                            <li>Transferencia Bancaria: CR59010200009494628247 - Fabricio Alvarez</li>
                            <li>SINPE Móvil: 8483-9675 - Fabricio Alvarez</li>
                            <li>Cuentas BAC: 949462824 - Fabricio Alvarez</li>
                        </ul>
                    </div>

                    {/* Botón de confirmación */}
                    <div className="mt-6">
                        <button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600  border-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                            onClick={executeRecaptcha} disabled={isLoading}>
                            {isLoading ? (
                                <RefreshIcon className="animate-spin h-5 w-5 mr-3" />
                            ) : "Confirmar Pedido"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentOptionsPopup;


