import React, {useState, useEffect } from 'react';
import Navbar from '../Components/NavBar';
import desarrollo from '../Assets/Img/concept.jpg';
import Services from '../Components/Services';
import Footer from '../Components/Footer';
import Services2 from '../Components/Services2';
import InformationPopup from '../Components/InformationPopup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CompanyValues from '../Components/CompanyValues';

const TridnioMain = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    const [popupOpen, setPopupOpen] = useState(false);

    return (<div className="flex flex-col min-h-screen bg-gray-100 font-sans">
        <Navbar />
        <InformationPopup isOpen={popupOpen} onClose={() => setPopupOpen(false)} />
        <main className="flex-grow pt-[68px] bg-blueGray-900">
            <section id="about-us" className="py-20">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="bg-darkBlue shadow-xl rounded-lg overflow-hidden lg:flex">
                        {/* Contenedor de texto y botón */}
                        <div data-aos="fade-up" className="lg:w-1/2 p-10 text-center lg:text-left">
                            <h1 className="text-4xl font-bold mb-4 text-lime-600">
                                Navegando hacia el éxito en el panorama digital
                            </h1>
                            <p className="mb-8 text-gray-300">
                                Nuestra empresa de desarrollo de software ofrece soluciones avanzadas que ayudan a los negocios a crecer y tener éxito en línea, con servicios especializados en SEO, PPC, marketing en redes sociales y creación de contenido.
                            </p>
                            
                        </div>

                        {/* Contenedor de la imagen */}
                        <div data-aos="fade-up" data-aos-delay="200" className="lg:w-1/2">
                            <img
                                src={desarrollo}
                                alt="Hero Image"
                                className="w-full h-auto object-cover"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Services id="apps" />
            <Services2 id="services2" />
            <CompanyValues/>
            {/* Additional sections go here */}
        </main>
        <Footer />
    </div>
    );
};

export default TridnioMain;