import React, { useState } from 'react';

const InformationPopup = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        nombre: '',
        asunto: '',
        descripcion: '',
        correo: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData); // Aquí puedes manejar la lógica de envío a un servidor, etc.
        onClose(); // Cierra el pop-up después de enviar
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex justify-center items-center px-4">
            <div className="bg-white p-6 md:p-8 lg:p-10 rounded-lg shadow-xl max-w-md w-full">
                <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Solicita información</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                        type="text"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleChange}
                        placeholder="Nombre"
                        className="mb-4 w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <input
                        type="email"
                        name="correo"
                        value={formData.correo}
                        onChange={handleChange}
                        placeholder="Correo"
                        className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <input
                        type="text"
                        name="asunto"
                        value={formData.asunto}
                        onChange={handleChange}
                        placeholder="Asunto"
                        className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <textarea
                        name="descripcion"
                        value={formData.descripcion}
                        onChange={handleChange}
                        placeholder="Descripción"
                        className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        rows="4"
                    ></textarea>
                    <div className="flex justify-between items-center">
                        <button type="submit" className="inline-block bg-lime-500 hover:bg-lime-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300">
                            Enviar
                        </button>
                        <button type="button" onClick={onClose} className="inline-block bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-6 rounded-lg transition duration-300">
                            Cerrar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InformationPopup;
