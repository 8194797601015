import React from 'react';

const TicketTab = ({ title, isSelected, onCloseClick, onClick }) => (
    <div
        className={`inline-flex items-center justify-between border ${isSelected ? 'border-b-0 bg-white' : 'bg-gray-100'} rounded-t-md px-4 py-2 mx-1 cursor-pointer`}
        onClick={onClick} // This makes the tab selectable
    >
        <span className={`text-sm ${isSelected ? 'text-blue-600' : 'text-gray-800'}`}>{title}</span>
        {isSelected && (
            <button onClick={(e) => { e.stopPropagation(); onCloseClick(); }} className="ml-2 text-gray-400 hover:text-gray-600">
                &times;
            </button>
        )}
    </div>
);


export default TicketTab