// Shop.jsx

import React, { useState } from 'react';
import ShopNavbar from '../Components/Shop/ShopNavbar';
import MainShop from '../Components/Shop/MainShop';
import CartPopup from '../Components/Shop/CartPopup';
import toast from 'react-hot-toast';
import UserInformationPopup from '../Components/Shop/UserInformationPopup';
import PaymentOptionsPopup from '../Components/Shop/PaymentOptionsPopup';
import Footer from '../Components/Shop/Footer';
import FeaturesComponent from '../Components/Shop/FeatureComponent';
const generateOrderId = () => {
    const randomNum = Math.floor(1000 + Math.random() * 9000);
    const datePart = new Date().getTime().toString().slice(-5);
    return `TRIDNIO-${randomNum}-${datePart}`;
};

const Shop = () => {
    const [cartItems, setCartItems] = useState([]);
    const [customerEmail, setCustomerEmail] = useState('');
    const [showCartPopup, setShowCartPopup] = useState(false);
    const [showUserInformationPopup, setShowUserInformationPopup] = useState(false);
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [userData, setUserData] = useState(null);
    const [shippingCost, setShippingCost] = useState(2000);

    const toggleCartPopup = () => {
        setShowCartPopup(!showCartPopup);
    };
    const closeAllPopups = () => {
        setShowCartPopup(false);
        setShowUserInformationPopup(false);
        setShowPaymentOptions(false);
    };

    const addToCart = (newItem) => {
        setCartItems(prevItems => {
            // Buscar si el artículo ya existe con el mismo id, talla y color
            const existentItem = prevItems.find(item => item.id === newItem.id && item.talla === newItem.talla && item.color === newItem.color);
            if (existentItem) {
                // Si existe, solo incrementa la cantidad
                return prevItems.map(item =>
                    item.id === newItem.id && item.talla === newItem.talla && item.color === newItem.color ? { ...item, cantidad: item.cantidad + newItem.cantidad } : item
                );
            } else {
                // Si no existe, añade como un nuevo artículo en el carrito
                return [...prevItems, newItem];
            }
        });
    };


    const handleProceedToCheckout = () => {
        if (cartItems.length > 0) {
            setShowCartPopup(false);
            setShowUserInformationPopup(true);
        } else {
            toast.error('El carrito está vacío.');
        }
    };

    //const handleCloseUserInformation = () => {
    //    setShowUserInformationPopup(false);
    // };

    const handleConfirmUserInformation = (userInfo) => {
        //console.log('User Information:', userInfo);
        setUserData(userInfo);  // Save the user info
        setCustomerEmail(userInfo.email);
        setShippingCost(userInfo.shippingCost); // Correctly set the shipping cost
        const newOrderId = generateOrderId();
        setOrderId(newOrderId);
        setShowPaymentOptions(true);
    };


    const closePaymentOptions = () => {
        setShowPaymentOptions(false);
    };

    const removeFromCart = (productId) => {
        setCartItems(currentItems => {
            //console.log("Intentando eliminar producto", productId);
            const updatedItems = currentItems.filter(item => item.id !== productId);
            if (updatedItems.length < currentItems.length) {
                //console.log("Producto eliminado", productId);
                toast.success('Producto eliminado del carrito', {
                    id: productId
                });

            } else {
                toast.error('Error al eliminar el producto');
            }
            return updatedItems;
        });
    };


    //Payment
    const resetCart = () => {
        setCartItems([]);  // Limpiar el carrito
    };

    // Asegúrate de que estás enviando los datos correctos al backend
    // Include user data as an argument
    const sendOrder = () => {
        // Calcula el subtotal de los artículos en el carrito
        const subtotal = cartItems.reduce((acc, item) => acc + item.cantidad * item.precio, 0);

        // Determinar si el envío es gratuito basado en el subtotal
        const isFreeShipping = subtotal > 20000;

        const dataToSend = {
            items: cartItems.map(item => ({
                name: item.nombre,
                quantity: item.cantidad,
                price: item.precio,
                size: item.talla, // Asegúrate de incluir la talla
                color: item.color // Asegúrate de incluir el color
            })),
            customerEmail: userData.email,
            // Aplica costo de envío gratis si es aplicable
            shippingCost: isFreeShipping ? 0 : shippingCost,
            orderId: orderId,
            userData
        };

        fetch('https://tridnio-api-73dsl.ondigitalocean.app/api/send-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend)
        })
            .then(response => response.json())
            .then(data => {
                toast.success('Orden enviada con éxito');
                resetCart();
                closeAllPopups();
            })
            .catch(error => {
                console.error('Error sending order:', error);
                toast.error('Error al enviar la orden');
            });
    };



    return (
        <div className="flex flex-col min-h-screen" style={{ paddingTop: '4rem' }}>
            <ShopNavbar cartItems={cartItems} toggleCartPopup={toggleCartPopup} />
            <div className="container mx-auto mt-10 px-4 sm:px-6 flex-grow">
                <h1 className="text-2xl font-bold">Tridnio Store</h1>
                <p className="text-center sm:text-left">Explora nuestros productos y servicios exclusivos.</p>
                <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mt-4" role="alert">
                    <p className="font-bold">Aviso Importante para Nuestros Clientes</p>
                    <p>
                        En Tridnio Store, valoramos su preferencia. Les informamos que los pedidos realizados antes del 15 y del 30 de cada mes serán procesados y despachados en un plazo de 3 a 5 días hábiles. El tiempo de entrega puede variar según la ubicación y el método de envío.
                    </p>
                    <p>
                        Agradecemos su confianza y estamos a su disposición para cualquier consulta.
                    </p>
                </div>

                <div className="bg-blue-100 border-l-4 border-green-500 text-green-700 p-4 mt-4" role="alert">
                    <p className="font-bold">Promoción: Envío gratis en compras mayores a ¢20,000</p>
                </div>



                <MainShop addToCart={addToCart} cartItems={cartItems} />
            </div>
            {showCartPopup && (
                <CartPopup
                    isOpen={showCartPopup}
                    onClose={toggleCartPopup}
                    cartItems={cartItems}
                    removeFromCart={removeFromCart}
                    onProceedToCheckout={handleProceedToCheckout}
                />
            )}
            {showUserInformationPopup && (
                <UserInformationPopup
                    isOpen={showUserInformationPopup}
                    onClose={() => setShowUserInformationPopup(false)}
                    onConfirm={handleConfirmUserInformation}
                />
            )}
            {showPaymentOptions && (
                <PaymentOptionsPopup
                    isOpen={showPaymentOptions}
                    onClose={closePaymentOptions}
                    cartItems={cartItems}
                    resetCart={resetCart}
                    sendOrder={sendOrder}
                    orderId={orderId}
                    shippingCost={shippingCost}
                />
            )}
            <FeaturesComponent />
            <Footer />
        </div>
    );


};

export default Shop;





