import React, { useState, useEffect } from 'react';
import locationData from '../../Assets/costarica.json'
import toast from 'react-hot-toast';


const UserInformationPopup = ({ isOpen, onClose, onConfirm }) => {
    const [userData, setUserData] = useState({
        name: '',
        lastName: '',
        cedula: '',
        province: '',
        canton: '',
        distrito: '',
        phone: '',
        email: '',
        address: ''
    });
    const [cantons, setCantons] = useState({});
    const [districts, setDistricts] = useState([]);
    const [shippingCost, setShippingCost] = useState(null);
    const [emailError, setEmailError] = useState('');

    const isFormValid = () => {
        return userData.name && userData.lastName && userData.province && userData.canton &&
            userData.distrito && userData.phone && userData.email && userData.address;
    };

    useEffect(() => {
        if (userData.province) {
            const selectedCantons = locationData.provincias[userData.province].cantones;
            setCantons(selectedCantons);
            setUserData(prev => ({ ...prev, canton: '', distrito: '' }));

            // Actualizar el costo de envío según la provincia seleccionada
            const provinceName = locationData.provincias[userData.province].nombre;
            if (provinceName === 'Cartago' || provinceName === 'San Jose') {
                setShippingCost(2000);
            } else {
                setShippingCost(2500);
            }
        }
    }, [userData.province]);


    useEffect(() => {
        // Validar el correo electrónico cada vez que el usuario modifica el campo
        if (userData.email && !/\S+@\S+\.\S+/.test(userData.email)) {
            setEmailError('Por favor, introduce un correo electrónico válido.');
        } else {
            setEmailError('');
        }
    }, [userData.email]);

    useEffect(() => {
        if (userData.canton) {
            const selectedDistricts = Object.values(cantons[userData.canton].distritos);
            setDistricts(selectedDistricts);
        }
    }, [userData.canton, cantons]);

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Verifica si todos los campos requeridos están completos
        if (!isFormValid()) {
            toast.error('Por favor, complete todos los campos requeridos.');
            return;
        }
        // Verifica errores de correo electrónico
        if (emailError) {
            toast.error('Por favor, corrige los errores antes de enviar.');
            return;
        }
        // Si todo está correcto, procede con la confirmación
        onConfirm({ ...userData, shippingCost });
        toast.success('Información enviada correctamente!');
    };




    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 overflow-y-auto z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{ paddingTop: '4rem' }} >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose}></div>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">Información de Envío</h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">Complete los siguientes campos para continuar con su pedido. Es crucial proporcionar un <strong>correo electrónico y número de teléfono válidos</strong> para facilitar la comunicación.</p>
                                    {emailError && <p className="text-red-500">{emailError}</p>}
                                    {shippingCost && <p className="text-red-500">Costo de Envío: ¢{shippingCost}</p>}
                                </div>
                            </div>
                        </div>
                        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                            <div className="rounded-md shadow-sm space-y-3">
                                <input type="text" name="name" required placeholder="Nombre" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" value={userData.name} onChange={handleChange} />
                                <input type="text" name="lastName" required placeholder="Apellido" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" value={userData.lastName} onChange={handleChange} />
                                <input type="text" name="cedula" required placeholder="Cédula" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" value={userData.cedula} onChange={handleChange} />
                                <select name="province" required className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" value={userData.province} onChange={handleChange}>
                                    <option value="">Seleccione Provincia</option>
                                    {Object.entries(locationData.provincias).map(([key, { nombre }]) => (
                                        <option key={key} value={key}>{nombre}</option>
                                    ))}
                                </select>
                                <select name="canton" required className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" value={userData.canton} onChange={handleChange}>
                                    <option value="">Seleccione Cantón</option>
                                    {Object.entries(cantons).map(([key, { nombre }]) => (
                                        <option key={key} value={key}>{nombre}</option>
                                    ))}
                                </select>
                                <select name="distrito" required className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" value={userData.distrito} onChange={handleChange}>
                                    <option value="">Seleccione Distrito</option>
                                    {districts.map((distrito, index) => (
                                        <option key={index} value={distrito}>{distrito}</option>
                                    ))}
                                </select>
                                <input type="text" name="address" required placeholder="Direccion" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" value={userData.address} onChange={handleChange} />
                                <input type="text" name="phone" required placeholder="Teléfono" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" value={userData.phone} onChange={handleChange} />
                                <input type="email" name="email" required placeholder="Correo" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" value={userData.email} onChange={handleChange} />
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                <button type="button" className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={onClose}>
                                    Cancelar
                                </button>
                                <button type="submit" className="bg-blue-600 border border-blue-600 text-white hover:bg-blue-700 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={handleSubmit}>
                                    Confirmar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserInformationPopup;

