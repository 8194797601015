// Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-white text-black text-center p-4 md:p-6 lg:p-8">
            <div className="container mx-auto">
                <p className="text-sm md:text-base">¿Necesitas Informacion? Llama al <a href="tel:+506 84839675" className="font-bold underline">+506 84839675</a> o escribe a <a href="mailto:info@tridniostudios.com" className="font-bold underline">info@tridniostudios.com</a></p>
            </div>
        </footer>
    );
};

export default Footer;

