import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const CompanyValues = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className="py-8 bg-gray-800">
            <div className="container mx-auto px-4">
                <div className="text-center my-10">
                    <h2 className="text-3xl font-bold text-white mb-4">Sistemas a tu altura</h2>
                    <h3 className="text-2xl text-green-500 font-bold mb-6">Nuestro Compromiso</h3>
                    <p className="text-gray-400 mb-8">
                        En Tridnio Studios desarrollamos soluciones de software a la medida que impulsan la innovación en todas las industrias, adaptándonos a tus necesidades específicas para alcanzar tus objetivos estratégicos.
                    </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <ValueCard
                        title="Misión"
                        content="Desarrollar soluciones de software personalizadas y de alta calidad que empoderen a todas las industrias y promuevan el crecimiento y la innovación en sus respectivos campos."
                    />
                    <ValueCard
                        title="Visión"
                        content="Convertirnos en un referente de excelencia y confiabilidad en el desarrollo de software personalizado, expandiendo nuestro alcance global para ser socios estratégicos de empresas de todas las áreas."
                    />
                    <ValueCard
                        title="Valores"
                        content={
                            <ul className="list-disc ml-4 space-y-2 text-gray-400">
                                <li>Innovación Continua</li>
                                <li>Compromiso con la Calidad</li>
                                <li>Adaptabilidad y Flexibilidad</li>
                                <li>Integridad y Ética Profesional</li>
                                <li>Colaboración y Crecimiento Mutuo</li>
                            </ul>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

const ValueCard = ({ title, content }) => (
    <div data-aos="fade-up" className="bg-gray-700 p-6 rounded-lg shadow-md">
        <h3 className="text-2xl font-semibold text-white mb-3">{title}</h3>
        {typeof content === 'string' ? (
            <p className="text-gray-400">{content}</p>
        ) : (
            content
        )}
    </div>
);

export default CompanyValues;
