
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TridnioMain from './Pages/TridnioMain'
import Shop from './Pages/Shop';
import { Toaster } from 'react-hot-toast';
import DashboardPage from './Pages/DashboardPage';
import LoginPage from './Pages/LoginPage';
import ProtectedRoute from './Components/ProtectedRoute';

function App() {
  return (
    <BrowserRouter>
        <Toaster position="top-center" reverseOrder={false} />
        <Routes>
            <Route path="/" element={<TridnioMain />} />
            <Route path="/store" element={<Shop />} />
            <Route path="/PptfvfSZ1yyIo6RejmAQ" element={<LoginPage />} />
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            } />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
