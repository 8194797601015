import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    if (!token) {
        // Si no hay token, redirige al login
        return <Navigate to="/PptfvfSZ1yyIo6RejmAQ" />;
    }

    return children;  // Renderiza el componente hijo si el token existe
};

export default ProtectedRoute;
