import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const LoginPage = () => {
    const [code, setCode] = useState('');

    const handleInputChange = (event) => {
        setCode(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        toast.promise(
            fetch('https://tridnio-api-73dsl.ondigitalocean.app/api/admin/validate-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code })
            })
            .then((response) => response.json())
            .then((data) => {
                if (data.token) {
                    console.log("Login success:", data);
                    localStorage.setItem('token', data.token);
                    window.location.href = '/dashboard';  // Redirect to dashboard
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(new Error(data.message));
                }
            }),
            {
                loading: 'Validating code...',
                success: 'Login successful!',
                error: (err) => `Login failed: ${err.message}`,
            }
        );
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-800">
            <Toaster />
            <form onSubmit={handleSubmit} className="p-8 bg-gray-900 rounded shadow-lg">
                <div className="mb-4">
                    <label htmlFor="code" className="block text-sm font-medium text-gray-300">
                        Ingrese su código
                    </label>
                    <input
                        type="text"
                        id="code"
                        value={code}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="1234ABCD"
                        required
                        style={{ backgroundColor: "#2D2D2D", color: "#C7C7C7" }}
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Acceder
                </button>
            </form>
        </div>
    );
};

export default LoginPage;
