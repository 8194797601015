import React, { useState } from 'react';
import Tridnio from '../Assets/Img/TRIDNIO2.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = (event) => {
        if (event) event.stopPropagation();
        setIsOpen(!isOpen);
    };

    return (
        <>
            <header className="fixed top-0 z-50 bg-white shadow-md w-full">
                <div className="container mx-auto py-4 flex justify-between items-center">
                    <img src={Tridnio} alt="Logo de Tridnio" className="h-auto w-32 md:w-48 lg:w-40 object-contain" />
                    <div className="flex space-x-4 items-center">
                        {/* Botón de tienda visible en ambos dispositivos */}
                        <Link to="/store" className="px-4 py-2 rounded-lg bg-gradient-to-r from-green-600 to-green-500 text-white shadow-lg hover:shadow-xl transition-all ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 md:hidden">Tienda</Link>
                        <Link to="/store" className="hidden md:block px-4 py-2 rounded-lg bg-gradient-to-r from-green-600 to-green-500 text-white shadow-lg hover:shadow-xl transition-all ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50">Tienda</Link>
                        {/* Botón de menú hamburguesa visible en móviles */}
                    </div>
                </div>
            </header>
            
        </>
    );
};

export default Navbar;
