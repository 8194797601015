import React, { useEffect } from 'react';
import { FaMoneyCheckAlt, FaBook } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Services = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <div className="py-8 bg-gray-800">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-white mb-8">Nuestras Aplicaciones</h2>
                <p className="text-gray-400 text-center mb-8">
                    Nos especializamos en el desarrollo de aplicaciones innovadoras, diseñadas para potenciar el aprendizaje y optimizar procesos de finanzas y ventas. Descubre herramientas que transformarán la manera en la que interactúas con la educación y la gestión empresarial.
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <ServiceCard
                        Icon={FaBook}
                        title="Aplicación Educativa"
                        linkText="Proximamente"
                        linkHref="#"
                    />
                    <ServiceCard
                        Icon={FaMoneyCheckAlt}
                        title="Aplicación POS"
                        linkText="Proximamente"
                        linkHref="#"
                    />
                </div>

            </div>
        </div>
    );
};

const ServiceCard = ({ Icon, title, linkText, linkHref }) => (
    <div data-aos="fade-up" className="flex flex-col items-center bg-gray-700 p-6 rounded-lg shadow-md">
        <Icon className="text-4xl text-green-500 mb-4" />
        <h3 className="text-xl font-semibold text-white">{title}</h3>
        <a href={linkHref} className="mt-4 inline-block text-green-400 hover:text-green-300">
            {linkText}
        </a>
    </div>
);

export default Services;
