const TicketListView = ({ tickets, onTicketSelect }) => (
    <div className="overflow-x-auto mt-4">
        <table className="min-w-full leading-normal">
            <thead>
                <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">ID</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Subject</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Priority</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date Opened</th>
                </tr>
            </thead>
            <tbody>
                {tickets.map((ticket, index) => (
                    <tr key={index} onClick={() => onTicketSelect(ticket)} className="cursor-pointer hover:opacity-60">
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{ticket.id}</p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{ticket.subject}</p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <span className={`inline-block px-3 py-1 font-semibold text-sm leading-tight ${ticket.status === 'In process' ? 'text-yellow-700 bg-yellow-100' : ticket.status === 'Sent' ? 'text-orange-700 bg-orange-100' : 'text-green-700 bg-green-100'}`}>
                                {ticket.status}
                            </span>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <span className={`inline-block px-3 py-1 font-semibold text-sm leading-tight ${ticket.priority === 'Unpaid' ? 'text-red-700 bg-red-100' : 'text-green-700 bg-green-100'}`}>
                                {ticket.priority}
                            </span>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p className="text-gray-900 whitespace-no-wrap">{ticket.dateOpened}</p>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default TicketListView;
