// MainShop.js

import React, { useState } from 'react';
import ProductCard from '../ProductCard';


//Camisas-Bug

import bugamarillo from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-amarillo.png'
import bugazul from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-azul.png'
import bugazulcielo from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-azulcielo.png'
import bugazuloscuro from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-azuloscuro.png'
import bugblanco from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-blanco.png'
import buggris from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-gris.png'
import bugmorado from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-morado.png'
import bugnaranja from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-naranja.png'
import bugnegro from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-negro.png'
import bugrojo from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-rojo.png'
import bugrosado from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-rosado.png'
import bugsilver from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-silver.png'
import bugverde from '../../Assets/Img/CAMISAS FINALES/Bug/Bug-verde.png'

//Code
import codeAmarillo from '../../Assets/Img/CAMISAS FINALES/Code/Code-amarillo.png';
import codeAzul from '../../Assets/Img/CAMISAS FINALES/Code/Code-azul.png';
import codeAzulCielo from '../../Assets/Img/CAMISAS FINALES/Code/Code-azulcielo.png';
import codeAzulOscuro from '../../Assets/Img/CAMISAS FINALES/Code/Code-azuloscuro.png';
import codeBlanco from '../../Assets/Img/CAMISAS FINALES/Code/Code-blanco.png';
import codeGris from '../../Assets/Img/CAMISAS FINALES/Code/Code-gris.png';
import codeMorado from '../../Assets/Img/CAMISAS FINALES/Code/Code-morado.png';
import codeNaranja from '../../Assets/Img/CAMISAS FINALES/Code/Code-naranja.png';
import codeNegro from '../../Assets/Img/CAMISAS FINALES/Code/Code-negro.png';
import codeRojo from '../../Assets/Img/CAMISAS FINALES/Code/Code-rojo.png';
import codeRosado from '../../Assets/Img/CAMISAS FINALES/Code/Code-rosado.png';
import codeSilver from '../../Assets/Img/CAMISAS FINALES/Code/Code-silver.png';
import codeVerde from '../../Assets/Img/CAMISAS FINALES/Code/Code-verde.png';

//debug
import debuggingAmarillo from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-amarillo.png';
import debuggingAzul from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-azul.png';
import debuggingAzulCielo from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-azulcielo.png';
import debuggingAzulOscuro from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-azuloscuro.png';
import debuggingBlanco from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-blanco.png';
import debuggingGris from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-gris.png';
import debuggingMorado from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-morado.png';
import debuggingNaranja from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-naranja.png';
import debuggingNegro from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-negro.png';
import debuggingRojo from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-rojo.png';
import debuggingRosado from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-rosado.png';
import debuggingSilver from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-silver.png';
import debuggingVerde from '../../Assets/Img/CAMISAS FINALES/Debugging/Debugging-verde.png';

//fire
import fireAmarillo from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-amarillo.png';
import fireAzul from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-azul.png';
import fireAzulCielo from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-azulcielo.png';
import fireAzulOscuro from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-azuloscuro.png';
import fireBlanco from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-blanco.png';
import fireGris from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-gris.png';
import fireMorado from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-morado.png';
import fireNaranja from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-naranja.png';
import fireNegro from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-negro.png';
import fireRojo from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-rojo.png';
import fireRosado from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-rosado.png';
import fireSilver from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-silver.png';
import fireVerde from '../../Assets/Img/CAMISAS FINALES/Fire/Fire-verde.png';

//soft
import softweirdAmarillo from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-amarillo.png';
import softweirdAzul from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-azul.png';
import softweirdAzulCielo from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-azulcielo.png';
import softweirdAzulOscuro from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-azuloscuro.png';
import softweirdBlanco from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-blanco.png';
import softweirdGris from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-gris.png';
import softweirdMorado from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-morado.png';
import softweirdNaranja from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-naranja.png';
import softweirdNegro from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-negro.png';
import softweirdRojo from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-rojo.png';
import softweirdRosado from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-rosado.png';
import softweirdSilver from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-silver.png';
import softweirdVerde from '../../Assets/Img/CAMISAS FINALES/Softweird/Softweird-verde.png';

//Black mode
// Importaciones de imágenes para camisas negras
import GithubNegro from '../../Assets/Img/CamisasNegras/negro-github.png';
import DevNegro from '../../Assets/Img/CamisasNegras/negro-developer.png';
import IconPythonNegro from '../../Assets/Img/CamisasNegras/negro-icon-python.png';
import JavascriptNegro from '../../Assets/Img/CamisasNegras/negro-javascript.png';
import JsNegro from '../../Assets/Img/CamisasNegras/negro-js.png';
import JsCuadroNegro from '../../Assets/Img/CamisasNegras/negro-jscuadro.png';
import PyNegro from '../../Assets/Img/CamisasNegras/negro-py.png';
import PythonNegro from '../../Assets/Img/CamisasNegras/negro-python.png';
import RobotNegro from '../../Assets/Img/CamisasNegras/negro-robot.png';


// Imports para 'cinta'
import cintaAzulOscuro from '../../Assets/Img/ChatGPT/ChatGPT-cinta-azuloscuro.png';
import cintaBlanco from '../../Assets/Img/ChatGPT/ChatGPT-cinta-blanco.png';
import cintaNegro from '../../Assets/Img/ChatGPT/ChatGPT-cinta-negro.png';
import cintaRojo from '../../Assets/Img/ChatGPT/ChatGPT-cinta-rojo.png';
import cintaSilver from '../../Assets/Img/ChatGPT/ChatGPT-cinta-silver.png';

// Imports para 'español'
import españolAzulOscuro from '../../Assets/Img/ChatGPT/ChatGPT-español-azuloscuro.png';
import españolBlanco from '../../Assets/Img/ChatGPT/ChatGPT-español-blanco.png';
import españolNegro from '../../Assets/Img/ChatGPT/ChatGPT-español-negro.png';
import españolRojo from '../../Assets/Img/ChatGPT/ChatGPT-español-rojo.png';
import españolSilver from '../../Assets/Img/ChatGPT/ChatGPT-español-silver.png';

// Imports para 'icono'
import iconoAzulOscuro from '../../Assets/Img/ChatGPT/ChatGPT-icono-azuloscuro.png';
import iconoBlanco from '../../Assets/Img/ChatGPT/ChatGPT-icono-blanco.png';
import iconoNegro from '../../Assets/Img/ChatGPT/ChatGPT-icono-negro.png';
import iconoRojo from '../../Assets/Img/ChatGPT/ChatGPT-icono-rojo.png';
import iconoSilver from '../../Assets/Img/ChatGPT/ChatGPT-icono-silver.png';

// Imports para 'ingles'
import inglesAzulOscuro from '../../Assets/Img/ChatGPT/ChatGPT-ingles-azuloscuro.png';
import inglesBlanco from '../../Assets/Img/ChatGPT/ChatGPT-ingles-blanco.png';
import inglesNegro from '../../Assets/Img/ChatGPT/ChatGPT-ingles-negro.png';
import inglesRojo from '../../Assets/Img/ChatGPT/ChatGPT-ingles-rojo.png';
import inglesSilver from '../../Assets/Img/ChatGPT/ChatGPT-ingles-silver.png';

// Imports para 'university'
import universityAzulOscuro from '../../Assets/Img/ChatGPT/ChatGPT-university-azuloscuro.png';
import universityBlanco from '../../Assets/Img/ChatGPT/ChatGPT-university-blanco.png';
import universityNegro from '../../Assets/Img/ChatGPT/ChatGPT-university-negro.png';
import universityRojo from '../../Assets/Img/ChatGPT/ChatGPT-university-rojo.png';
import universitySilver from '../../Assets/Img/ChatGPT/ChatGPT-university-silver.png';

import gorraChatGPT from '../../Assets/Img/Gorras/gorra-chatgpt.png'
import gorraCode from '../../Assets/Img/Gorras/gorra-code.png'
import gorraSoft from '../../Assets/Img/Gorras/gorra-softweird.png'



const products = [
    {
        id: 1,
        name: 'Código ON',
        description: '¡Para los que viven cada codigo como si fuera el último!',
        price: 11900,
        colors: {
            '#FFD700': codeAmarillo,
            '#0000FF': codeAzul,
            '#87CEEB': codeAzulCielo,
            '#00008B': codeAzulOscuro,
            '#FFFFFF': codeBlanco,
            '#808080': codeGris,
            '#800080': codeMorado,
            '#FFA500': codeNaranja,
            '#000000': codeNegro,
            '#FF0000': codeRojo,
            '#FFC0CB': codeRosado,
            '#C0C0C0': codeSilver,
            '#008000': codeVerde,
        }
    },
    {
        id: 2,
        name: 'Depuración Determinada',
        description: 'Para los héroes que encuentran esos horribles bugs.',
        price: 11900,
        colors: {
            '#FFD700': debuggingAmarillo,
            '#0000FF': debuggingAzul,
            '#87CEEB': debuggingAzulCielo,
            '#00008B': debuggingAzulOscuro,
            '#FFFFFF': debuggingBlanco,
            '#808080': debuggingGris,
            '#800080': debuggingMorado,
            '#FFA500': debuggingNaranja,
            '#000000': debuggingNegro,
            '#FF0000': debuggingRojo,
            '#FFC0CB': debuggingRosado,
            '#C0C0C0': debuggingSilver,
            '#008000': debuggingVerde,
        }
    },
    {
        id: 3,
        name: 'Fuego en el Código',
        description: 'Para los que programan con pasión e intensidad.',
        price: 11900,
        colors: {
            '#FFD700': fireAmarillo,
            '#0000FF': fireAzul,
            '#87CEEB': fireAzulCielo,
            '#00008B': fireAzulOscuro,
            '#FFFFFF': fireBlanco,
            '#808080': fireGris,
            '#800080': fireMorado,
            '#FFA500': fireNaranja,
            '#000000': fireNegro,
            '#FF0000': fireRojo,
            '#FFC0CB': fireRosado,
            '#C0C0C0': fireSilver,
            '#008000': fireVerde,
        }
    },
    {
        id: 4,
        name: 'Rarezas de Software',
        description: 'Para los que disfrutan de las excentricidades del desarrollo.',
        price: 11900,
        colors: {
            '#FFD700': softweirdAmarillo,
            '#0000FF': softweirdAzul,
            '#87CEEB': softweirdAzulCielo,
            '#00008B': softweirdAzulOscuro,
            '#FFFFFF': softweirdBlanco,
            '#808080': softweirdGris,
            '#800080': softweirdMorado,
            '#FFA500': softweirdNaranja,
            '#000000': softweirdNegro,
            '#FF0000': softweirdRojo,
            '#FFC0CB': softweirdRosado,
            '#C0C0C0': softweirdSilver,
            '#008000': softweirdVerde,
        }
    },
    {
        id: 6,
        name: 'ChatGPT ¿Como puedo ayudarte?',
        description: '¡Lleva a tu compañero favorito a todas partes!',
        price: 11900,
        colors: {
            '#00008B': españolAzulOscuro,
            '#FFFFFF': españolBlanco,
            '#000000': españolNegro,
            '#FF0000': españolRojo,
            '#C0C0C0': españolSilver,
        }
    },
    {
        id: 7,
        name: 'ChatGPT Icono',
        description: 'Representa la icónica imagen de ChatGPT con un diseño minimalista y moderno.',
        price: 11900,
        colors: {
            '#00008B': iconoAzulOscuro,
            '#FFFFFF': iconoBlanco,
            '#000000': iconoNegro,
            '#FF0000': iconoRojo,
            '#C0C0C0': iconoSilver,
        }
    },
    {
        id: 8,
        name: 'ChatGPT How can I help you?',
        description: 'Para los lingüistas y amantes del inglés, una camisa que comunica tu pasión.',
        price: 11900,
        colors: {
            '#00008B': inglesAzulOscuro,
            '#FFFFFF': inglesBlanco,
            '#000000': inglesNegro,
            '#FF0000': inglesRojo,
            '#C0C0C0': inglesSilver,
        }
    },
    {
        id: 10,
        name: 'GitHub',
        description: '¡La camisa perfecta para hacer merge entre el código y el placer!',
        price: 11900,
        colors: {
            '#000000': GithubNegro,
        }
    },
    {
        id: 11,
        name: 'Developer Hub',
        description: '¡La camisa ideal para desarrolladores que aman su trabajo!',
        price: 11900,
        colors: {
            '#000000': DevNegro,
        }
    },
    {
        id: 12,
        name: 'Icono Python',
        description: 'Para los aficionados a Python que llevan la serpiente en el corazón.',
        price: 11900,
        colors: {
            '#000000': IconPythonNegro,
        }
    },
    {
        id: 13,
        name: 'JavaScript',
        description: 'Celebra tu lenguaje favorito con estilo.',
        price: 11900,
        colors: {
            '#000000': JavascriptNegro,
        }
    },
    {
        id: 14,
        name: 'JS',
        description: 'Simple y al grano, para los entusiastas de JS.',
        price: 11900,
        colors: {
            '#000000': JsNegro,
        }
    },
    {
        id: 15,
        name: 'Cuadro JS',
        description: 'El logo de JS en un diseño minimalista y elegante.',
        price: 11900,
        colors: {
            '#000000': JsCuadroNegro,
        }
    },
    {
        id: 16,
        name: 'Py',
        description: 'Una camisa para mostrar tu amor por Python.',
        price: 11900,
        colors: {
            '#000000': PyNegro,
        }
    },
    {
        id: 17,
        name: 'Python',
        description: 'Python en su forma más pura, lleva tu pasión a todos lados.',
        price: 11900,
        colors: {
            '#000000': PythonNegro,
        }
    },
    {
        id: 18,
        name: 'I am not a Robot',
        description: 'Para los amantes de la automatización',
        price: 11900,
        colors: {
            '#000000': RobotNegro,
        }
    },
    {
        id: 19,
        name: 'Gorra ChatGPT',
        description: 'Una gorra elegante para mostrar tu interés en la IA.',
        price: 9900,
        colors: {
            '#000000': gorraChatGPT,
        },
        hasSizes: false
    },
    {
        id: 20,
        name: 'Gorra Code',
        description: 'Perfecta para los días de código al aire libre.',
        price: 9900,
        colors: {
            '#000000': gorraCode,
        },
        hasSizes: false
    },
    {
        id: 21,
        name: 'Gorra Softweird',
        description: 'Para los que aman lo peculiar y el software.',
        price: 9900,
        colors: {
            '#000000': gorraSoft,
        },
        hasSizes: false
    },
];


const MainShop = ({ addToCart, cartItems }) => {
    // Filtra los productos que NO son parte del modo negro para la lista general
    const productosGenerales = products.filter(product => product.id < 10);

    // Filtra los productos que SÍ son parte del modo negro
    const productosModoNegro = products.filter(product => product.id >9 && product.id < 19);

    const productosGorras = products.filter(product => product.id >= 19);

    return (
        <div className="container mx-auto mt-10">
            <div className="flex flex-wrap justify-center">
                {productosGenerales.map(product => (
                    <ProductCard key={product.id} product={product} addToCart={addToCart} />
                ))}
            </div>
            <h2 className="text-center font-bold text-2xl mt-10 mb-5">Black Mode</h2>
            <div className="flex flex-wrap justify-center">
                {productosModoNegro.map(product => (
                    <ProductCard key={product.id} product={product} addToCart={addToCart} />
                ))}
            </div>
            <h2 className="text-center font-bold text-2xl mt-10 mb-5">Gorras</h2>
            <div className="flex flex-wrap justify-center">
                {productosGorras.map(product => (
                    <ProductCard key={product.id} product={product} addToCart={addToCart} />
                ))}
            </div>
        </div>
    );
};

export default MainShop;


