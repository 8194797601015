import React from 'react';
import DashboardNavBar from '../Components/Dashboard/DashboardNavBar';
import TicketSystem from './TicketSystem';

function DashboardPage() {
    return (
        <div className="min-h-screen bg-gray-100">
            <DashboardNavBar
            />
            <main className="pt-[68px]">
                <TicketSystem/>
            </main>
        </div>
    );
}

export default DashboardPage;


