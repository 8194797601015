// FeaturesComponent.jsx
import React from 'react';
import { FaLock, FaPlane, FaUndo, FaLeaf } from 'react-icons/fa';

const features = [
    {
        Icon: FaLock,
        title: 'Pago Seguro',
        description: 'Tu seguridad es nuestra prioridad, pago seguro en cada compra.',
    },
    {
        Icon: FaPlane,
        title: 'Envío Rápido Nacional',
        description: 'Llevamos tus pedidos rápidamente a cualquier lugar de Costa Rica.',
    },
    {
        Icon: FaUndo,
        title: 'Devoluciones',
        description: 'Fáciles y hasta 30 días para tu tranquilidad.',
    },
    {
        Icon: FaLeaf,
        title: 'Producto Sostenible',
        description: 'Productos de alta calidad con un compromiso ambiental y social.',
    },
];

const FeatureCard = ({ Icon, title, description }) => (
    <div className="flex flex-col items-center p-4 bg-gray-100 shadow rounded-lg">
        <Icon className="mb-2 text-3xl text-gray-800" />
        <h3 className="mb-2 text-lg font-semibold">{title}</h3>
        <p className="text-sm text-gray-600 text-center">{description}</p>
    </div>
);

const FeaturesComponent = () => (
    <div className="px-4 py-8">
        <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
            ))}
        </div>
    </div>
);

export default FeaturesComponent;
