import React, { useEffect } from 'react';
import { FaLaptopCode, FaMobileAlt, FaServer, FaPaintBrush } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Services2 = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <div className="py-8 bg-gray-800">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-white mb-8">Nuestros Servicios</h2>
                <p className="text-gray-400 text-center mb-8">
                    Ofrecemos soluciones integrales en el desarrollo de software, desde aplicaciones móviles hasta plataformas web, asegurando una experiencia única y funcionalidades que se ajustan a tus necesidades.
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <ServiceCard
                        Icon={FaLaptopCode}
                        title="Desarrollo Web"
                        linkText=""
                        linkHref="#"
                    />
                    <ServiceCard
                        Icon={FaMobileAlt}
                        title="Desarrollo de Apps Móviles"
                        linkText=""
                        linkHref="#"
                    />
                    <ServiceCard
                        Icon={FaServer}
                        title="Desarrollo Backend"
                        linkText=""
                        linkHref="#"
                    />
                    <ServiceCard
                        Icon={FaPaintBrush}
                        title="Diseño UI/UX"
                        linkText=""
                        linkHref="#"
                    />
                </div>

            </div>
        </div>
    );
};

const ServiceCard = ({ Icon, title, linkText, linkHref }) => (
    <div data-aos="fade-up" className="flex flex-col items-center bg-gray-700 p-6 rounded-lg shadow-md">
        <Icon className="text-4xl text-green-500 mb-4" />
        <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
        <a href={linkHref} className="text-green-400 hover:text-green-300">
            {linkText}
        </a>
    </div>
);

export default Services2;
