import React from 'react';

const CartPopup = ({ isOpen, onClose, cartItems, removeFromCart, onProceedToCheckout }) => {
    if (!isOpen) return null;

    // Calcula el subtotal sumando los subtotales de todos los elementos del carrito
    const subtotal = cartItems.reduce((acc, item) => acc + item.cantidad * item.precio, 0);
     // Puede ser dinámico dependiendo de la lógica de tu aplicación
    const total = subtotal;

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>

                <div className="bg-white rounded-lg shadow-xl z-20 m-4 max-w-md w-full">
                    {/* Encabezado del Popup */}
                    <div className="p-4 border-b">
                        <h2 className="text-xl font-semibold text-gray-800">Detalle del carrito</h2>
                    </div>

                    {/* Contenido del Popup */}
                    <div className="p-4">
                        {cartItems.length > 0 ? (
                            <>
                                {/* Lista de artículos en el carrito */}
                                {cartItems.map((item, index) => (
                                    <div key={index} className="flex justify-start items-center mb-4">
                                        <div className="w-20 h-20 bg-gray-200 flex items-center justify-center">
                                            <img src={item.imagen} alt={item.nombre} className="w-full h-full object-cover" />
                                        </div>
                                        <div className="ml-4">
                                            <p className="font-bold text-lg">{item.nombre}</p>
                                            <p className="text-sm text-gray-600">Talla: {item.talla}</p>
                                            <p className="text-sm text-gray-600">Color: {item.color}</p>
                                            <p className="text-sm text-gray-600">Cantidad: {item.cantidad}</p>
                                            <p className="text-sm text-gray-600">Precio: ¢{item.precio}</p>
                                            <button
                                                onClick={() => removeFromCart(item.id)}
                                                className="text-red-500 hover:text-red-700 text-sm"
                                            >
                                                Eliminar
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                {/* Área de precios y totales */}
                                <div className="mb-4">
                                    <div className="flex justify-between">
                                        <span>Subtotal:</span>
                                        <span>¢{subtotal}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span>Envío:</span>
                                        <span className="text-xs font-medium">(Envío se calculará según la zona)</span>
                                    </div>
                                    <div className="flex justify-between font-bold">
                                        <span>Total:</span>
                                        <span>¢{total}</span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="text-center">
                                <p className="text-gray-800 font-semibold">El carrito está vacío.</p>
                            </div>
                        )}

                        {/* Botones de acción */}
                        <div className="flex items-center justify-around p-4 border-t">
                            <button
                                onClick={onClose}
                                className="px-6 py-2 text-sm text-gray-600 border rounded-md hover:bg-gray-100"
                            >
                                Seguir Comprando
                            </button>
                            {cartItems.length > 0 && (
                                <button
                                    onClick={onProceedToCheckout}
                                    className="px-6 py-2 text-sm text-white bg-blue-600 border border-blue-600 rounded-md hover:bg-blue-700"
                                >
                                    Proceder con la compra
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartPopup;
