import React, { useState } from 'react';
import { FaBars, FaTimes, FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';  // Importa useNavigate de react-router-dom

function DashboardNavBar({ onKnowledgeClick, onPOSaurClick }) {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();  // Hook para la navegación

    const handleLogout = () => {
        localStorage.removeItem('token');  // Elimina el token del almacenamiento local
        navigate('/PptfvfSZ1yyIo6RejmAQ');  // Redirige al usuario a la página de login
    };

    return (
        <nav className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white p-4 shadow-lg">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <FaUserCircle size={28} />
                    <span className="text-lg font-bold">Admin Panel</span>
                </div>
                <div className="hidden md:flex items-center space-x-4">
                    {/* Desktop buttons */}
                    <button onClick={handleLogout} className={`block py-2 px-4 text-lg text-white hover:bg-blue-500 rounded transition duration-300`}>Cerrar Sesión</button>
                </div>
                <button className="md:hidden" onClick={() => setIsNavOpen(!isNavOpen)}>
                    {isNavOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </button>
                <div className={`absolute top-0 left-0 right-0 transition-transform transform ${isNavOpen ? 'translate-y-20' : '-translate-y-full'} bg-white shadow-md rounded-b-lg`}>
                    {/* Mobile menu */}
                    <button onClick={handleLogout} className={`block py-2 px-4 text-lg text-indigo-600 hover:bg-blue-500 hover:text-white rounded transition duration-300`}>Cerrar Sesión</button>
                </div>
            </div>
        </nav>
    );
}

export default DashboardNavBar;



